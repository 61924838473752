<template>
  <div class="login-container minSize">
    <Header></Header>
    <Back @handleBack="handleBack" v-show="showType!=='join'"></Back>
    <LoginMenu v-if="showType==='join'" @handleLogin="handleLogin"></LoginMenu>
    <LoginForm :isClick="$route.params.isClick" v-else></LoginForm>
    <Footer></Footer>
  </div>
</template>
<script>
import loginMenu from "@/views/login/Login.Join.vue";
import loginForm from "@/views/login/Login.Form";
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer"
import Back from "@/components/back/Back";


export default {
  components: {
    LoginMenu: loginMenu,
    LoginForm: loginForm,
    Header,
    Footer,
    Back
  },
  data() {
    return {
      showType: 'join'
    }
  },
  mounted() {
    let that = this
    if(that.$route.params.isClick) {
      that.handleLogin()
    }
  },
  methods: {
    handleLogin() {
      this.showType = 'login'
    },
    handleBack() {
      this.showType = 'join'
      this.$route.params.isClick = undefined
    }
  }
}
</script>
<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 720px;
    height: 540px;
    background: #FFFFFF;
    flex: 1;
    //box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}

</style>
